import { string } from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from "react";
import PageHelmet from "components/PageHelmet";
import {
  Banner,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  DataTable,
  DropZone,
  FormLayout,
  Layout,
  Link,
  Page,
  RadioButton,
  Box,
  Select,
  Tag,
  Text,
  TextField,
  Tooltip,
} from "@shopify/polaris";
import Stepper from "components/Stepper";
import formatRow from "utils/formatRow";
import SaveBar from "components/SaveBar/SaveBar";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import editIMG from "img/settings.svg";
import useActionDialogs from "hooks/useActionDIalogs";
import Modal from "components/Modal";
import chunkArray from "utils/chunkArray";
import {
  addMerchantDac7,
  createDac7Report,
  editMerchantInfo,
  sendDac7Report,
  uploadMerchantsFile
} from '../../redux/features/declarations/declarationsSlice'
import { fetchDac7Countries } from '../../redux/features/other/otherSlice'
import { createSchemas } from "./schemas";
import HighlightableDataTable from "components/HighlightableDataTable";
import plusIMG from "img/plusBlue.svg";
import InfoTooltip from "components/InfoTooltip/InfoTooltip";
import { email } from "utils/validation";
import _, { uniqueId } from "lodash";
import { AlertIcon } from "icons";
import {
  MerchantNotifications,
  MerchantTypes,
  NotificationMethod,
  dialogNames,
} from "./constants";
import step1IMG from "img/step1.svg";
import succesIMG from "img/step1.svg";
import deleteIMG from "img/delete.svg";
import Datepicker from "components/Datepicker/Datepicker";
import moment from "moment";
import useAppDispatch from "hooks/useAppDispatch";
import { baseURL } from "constants";

const renderInput = ({
  id,
  type,
  inputMode,
  placeholder,
  value,
  onChange,
  validate,
  noValidate,
  infoTooltipText,
  ...props
}) => {
  const label = infoTooltipText ? (
    <InfoTooltip
      content={infoTooltipText}
      children={placeholder}
      style={{ marginTop: -3 }}
    />
  ) : (
    placeholder
  );
  return (
    <div style={{ minWidth: '60px' }}>
    <TextField
      id={id}
      type={type}
      inputMode={inputMode}
      label={label}
      value={value}
      onChange={(value) => onChange(value, id)}
      error={noValidate ? false : validate(value)}
      {...props}
    />
    </div>
  );
};

const renderSelect = ({
  id,
  placeholder,
  options,
  onChange,
  value,
  validate,
}) => {
  return (
    <Select
      id={id}
      label={placeholder}
      options={options}
      onChange={(value) => onChange(value, id)}
      value={value}
      error={validate(value)}
    />
  );
};

const renderDatepicker = ({
  id,
  placeholder,
  onChange,
  value,
  customFormat,
  datepickerProps,
}) => {
  return (
    <Datepicker
      label={placeholder}
      onChange={(value) => onChange(moment(value).format("yyyy-MM-DD"), id)}
      start={value}
      value={value}
      allowRange={false}
      customFormatDate={(date) => customFormat(date)}
      datepickerProps={datepickerProps}
    />
  );
};

const Dac7Report = ({ isImmovableProperty }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    dac7 = {},
    dac7_summary,
    creatingDac7Report: dac7Loading,
    editingMerchantInfo,
    editingMerchantInfoError,
    business_name,
    period,
    user_status,
    uploadingMerchants,
    addingMerchant,
    relevant_activities,
    dac7_files_examples,
    property_types,
    dataForDac7ReportInfo,
  } = useSelector((state) => state.declarations);
  const { countriesDac7 } = useSelector((state) => state.other);
  const [stepIndex, setStepIndex] = useState(1);
  const [notificationSendCheckbox, setNotificationSendCheckbox] =
    useState(false);
  const [isConfirmSubmitDialog, setConfirmSubmitDialog] = useState(false);
  const [merchantNotifications, setMerchantNotifications] = useState(
    MerchantNotifications.Lovat
  );
  const [notificationMethod, setNotificationMethod] = useState(
    NotificationMethod.Digital
  );
  const [merchantsFile, setMerchantsFile] = useState({});
  const [fillFormLoading, setFillFormLoading] = useState("");
  const [touched, setTouched] = useState({});
  const [returnEmail, setReturnEmail] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(null);
  
  const {
    actionDialogs,
    handleActionDialogsOpen,
    handleActionDialogsClose,
    editActionDialogCellData,
  } = useActionDialogs(dialogNames);
  
  useEffect(() => {
    if (!dac7) {
      navigate("/reports");
    } else {
      dispatch(fetchDac7Countries());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePrev = useCallback(() => {
    if (stepIndex === 1) {
      navigate(-1);
    } else {
      setStepIndex((step) => step - 1);
    }
  }, [navigate, stepIndex]);

  const handleNext = () => {
    setStepIndex((step) => step + 1);
  };

  const steps = [
    {
      title: t("dac7.step1"),
      icon: step1IMG,
    },
    {
      title: t("dac7.preliminaryReport"),
      icon: step1IMG,
    },
    {
      title: t("dac7.merchantNotifications"),
      icon: step1IMG,
    },
    {
      title: t("dac7.summary"),
      icon: step1IMG,
    },
  ];

  const {
    legalEntitySchema,
    merchantIdInput,
    naturalPersonSchema,
    relevantActivitySchema,
    relevantActivityImmovableSchema,
    immovablePropertyAdditionalInfoSchema,
    tableSchema,
  } = createSchemas({ t });

  const settingsColumn = useMemo(
    () => ({
      property: "",
      header: {
        label: "",
      },
      cell: {
        formatters: [
          (_, { rowData }) => (
            <Box display="flex" alignItems="center" gap="4">
              {!rowData.is_warning && (
                <img
                  src={succesIMG}
                  alt='successfully filled merchant info'
                  width='14px'
                />
              )}
              <Tooltip content={t("dac7.editMerchantInfo")}>
                <Button
                  plain
                  onClick={() => {
                    handleActionDialogsOpen("merchantSettings", rowData);
                  }}
                >
                  <img src={editIMG} alt='edit merchant info' />
                </Button>
              </Tooltip>
            </Box>
          ),
        ],
      },
    }),
    [handleActionDialogsOpen]
  );

  const downloadFile = (link) => {
    const from = link.indexOf("/") + 1;
    const fileName = link.substring(from);
    const a = document.createElement("a");
    a.setAttribute("href", link);
    a.setAttribute("download", fileName);
    a.style.display = "none";

    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(link);
    document.body.removeChild(a);
  };

  const createFirstRelevantActivity = useCallback(
    (amount) => {
      const newType = getNewType(amount, relevant_activities);

      const defaultRelevantActivityItem = {
        id: uniqueId("dac7-"),
        type: newType,
        data: {
          vat_withheld_q: [0, 0, 0, 0],
          total_gross_amount_q: [0, 0, 0, 0],
          total_amount_of_commissions_q: [0, 0, 0, 0],
          gross_amount_of_transactions_q: [0, 0, 0, 0],
          total_number_of_transactions_q: [0, 0, 0, 0],
          related_number_of_transactions_q: [0, 0, 0, 0],
        },
      };

      return defaultRelevantActivityItem;
    },
    [relevant_activities]
  );

  const fetchDataForStep2 = useCallback(() => {
    return createDac7Report({
      a: {},
      is_file: true,
      is_immovable: isImmovableProperty || dataForDac7ReportInfo?.is_immovable,
      country: dataForDac7ReportInfo?.country,
      business_name: dataForDac7ReportInfo?.business_name,
      period: dataForDac7ReportInfo?.period,
    });
  }, [business_name, period, isImmovableProperty]);

  const step2 = useCallback(() => {
    const columns = [...tableSchema, settingsColumn];
    const data = dac7 ?? [];
    const sortedRows = formatRow(data, columns);
    const isNextButtonDisabled = data.some((merchant) => merchant.is_warning);
    const isWarningMessage = data.some((merchant) => merchant.is_warning);

    const handleNextClick = () => {
      dispatch(fetchDataForStep2()).then(() => handleNext());
    };

    const handleManualUploadClick = () => {
      handleActionDialogsOpen("uploadMerchants");
    };

    return (
      <>
        <Card sectioned>
          <ButtonGroup>
            <Button onClick={handleManualUploadClick}>
              {t("dac7.manualUploadMerchantData")}
            </Button>
          </ButtonGroup>
        </Card>
        <Card>
          {isWarningMessage && (
            <Card.Section>
              <Box display="flex" alignItems="center" gap="2">
                <AlertIcon stroke={"#B8821B"} />
                <Text color='subdued'>{t("dac7.merchantsWithWarnings")}</Text>
              </Box>
            </Card.Section>
          )}
          <HighlightableDataTable
            columnContentTypes={[
              "text",
              "text",
              "text",
              "text",
              "text",
              "numeric",
            ]}
            headings={columns.map(({ header }) => (
              <Text fontWeight='semibold'>{header.label}</Text>
            ))}
            rowClassNameCallback={(row, rowData) => ({
              "bg-yellow": rowData.is_warning,
            })}
            rawItems={data}
            rows={sortedRows}
            hideScrollIndicator
            increasedTableDensity
            hoverable
          />
        </Card>
        <div style={{ margin: "0.5rem 0" }}>
          <Button
            plain
            icon={<img src={plusIMG} alt='plus' style={{ marginTop: 3 }} />}
            onClick={() => {
              const defaultRelevantActivityItem = createFirstRelevantActivity(
                []
              );
              handleActionDialogsOpen("addMerchant", {
                legal_status: MerchantTypes.legal,
                amount: [defaultRelevantActivityItem],
              });
            }}
          >
            {t("dac7.addNewMerchant")}
          </Button>
        </div>

        <SaveBar>
          <ButtonGroup>
            <Button onClick={handlePrev}>{t("createReturns.back")}</Button>
            <Button
              primary
              onClick={handleNextClick}
              loading={dac7Loading}
              disabled={isNextButtonDisabled}
            >
              {t("orderReg.next")}
            </Button>
          </ButtonGroup>
        </SaveBar>
      </>
    );
  }, [
    tableSchema,
    settingsColumn,
    dac7,
    handlePrev,
    dac7Loading,
    dispatch,
    fetchDataForStep2,
    handleActionDialogsOpen,
    createFirstRelevantActivity,
  ]);

  const step3 = useCallback(() => {
    const isNextButtonDisabled =
      merchantNotifications === MerchantNotifications.Lovat
        ? email(returnEmail)
        : false;
    const handlePrevClick = () => {
      dispatch(fetchDataForStep2()).then(() => handlePrev());
    };

    const validateEmail = (value) => {
      return touched.returnEmail && email(value);
    };

    const handleNextClick = () => {
      if (merchantNotifications === MerchantNotifications.Lovat) {
        handleActionDialogsOpen("notificationsCheckbox");
      } else {
        handleNext();
      }
    };

    return (
      <>
        <Card sectioned>
          <Box display="flex" flexDirection="column" gap="1" width="100%">
            <Box display="flex" alignItems="center" gap="2">
              <Text>{t("dac7.merchantNotifications")}</Text>
              <InfoTooltip content={<span>{t("dac7.ompFacilitators")}</span>} />
            </Box>
            <Box spacing="0">
              <RadioButton
                label={t("dac7.sendMerchantNotificationsViaLovatPlatform")}
                checked={merchantNotifications === MerchantNotifications.Lovat}
                onChange={() => {
                  setMerchantNotifications(MerchantNotifications.Lovat);
                }}
              />
              <RadioButton
                label={t("dac7.sendMerchantNotificationsMyself")}
                checked={merchantNotifications === MerchantNotifications.Myself}
                onChange={() => {
                  setMerchantNotifications(MerchantNotifications.Myself);
                }}
              />
            </Box>
            <br />
            {merchantNotifications === MerchantNotifications.Myself ? (
              <Box display="flex" flexDirection="column" gap="1" width="100%">
                <Text>{t("dac7.pleaseChooseNotificationMethod")}</Text>
                <Box spacing='0'>
                  <RadioButton
                    label={t("dac7.digital")}
                    checked={notificationMethod === NotificationMethod.Digital}
                    onChange={() => {
                      setNotificationMethod(NotificationMethod.Digital);
                    }}
                  />
                  <RadioButton
                    label={t("dac7.paper")}
                    checked={notificationMethod === NotificationMethod.Paper}
                    onChange={() => {
                      setNotificationMethod(NotificationMethod.Paper);
                    }}
                  />
                </Box>
              </Box>
            ) : (
              <Box spacing='1' fullWidth>
                <Box align="center">
                  <Text>{t("dac7.pleaseAddReturnEmailAddress")}</Text>
                  <InfoTooltip
                    content={
                      <span>
                        {t(
                          "dac7.thisAddressWillBeIndicatedAsSenderContactEmail"
                        )}
                      </span>
                    }
                  />
                </Box>
                <FormLayout>
                  <FormLayout.Group>
                    <TextField
                      placeholder='Return email address'
                      value={returnEmail}
                      type='email'
                      onChange={(value) => setReturnEmail(value)}
                      error={validateEmail(returnEmail)}
                      onBlur={() =>
                        setTouched((prevTouched) => ({
                          ...prevTouched,
                          returnEmail: true,
                        }))
                      }
                    />
                    <br />
                  </FormLayout.Group>
                </FormLayout>
              </Box>
            )}
          </Box>
        </Card>
        {Boolean(dac7_files_examples.length) && (
          <Card sectioned>
            <Box spacing='2'>
              <Text>{t("dac7.examplesOfMerchantNotificationPdfFiles")}</Text>
              <Box spacing='2'>
                {dac7_files_examples.map((filePath) => {
                  return (
                    <Link external url={`${baseURL}/${filePath}`}>
                      {filePath.split("/").reverse()[0]}
                    </Link>
                  );
                })}
              </Box>
            </Box>
          </Card>
        )}
        <SaveBar>
          <ButtonGroup>
            <Button onClick={handlePrevClick}>{t("createReturns.back")}</Button>
            <Button
              primary
              onClick={handleNextClick}
              loading={dac7Loading}
              disabled={isNextButtonDisabled}
            >
              {t("orderReg.next")}
            </Button>
          </ButtonGroup>
        </SaveBar>
      </>
    );
  }, [
    dac7Loading,
    dac7_files_examples,
    dispatch,
    fetchDataForStep2,
    handleActionDialogsOpen,
    handlePrev,
    merchantNotifications,
    notificationMethod,
    returnEmail,
    touched.returnEmail,
  ]);

  const fillForm = useCallback(
    (type) => {
      setFillFormLoading(type);
      const data = {
        [type]: true,
        a: {},
        merchant_notifications: merchantNotifications,
        is_immovable: isImmovableProperty || dataForDac7ReportInfo?.is_immovable,
        country: dataForDac7ReportInfo?.country,
        business_name: dataForDac7ReportInfo?.business_name,
        period: dataForDac7ReportInfo?.period,
      };

      if (merchantNotifications === MerchantNotifications.Myself) {
        data.notification_method = notificationMethod;
      } else {
        data.return_email = returnEmail;
      }

      if (isImmovableProperty) {
        data.is_immovable = true;
      }
      dispatch(sendDac7Report(data))
        .then((res) => {
          if (type === "submit") {
            handleActionDialogsClose("confirmSubmit");
            handleActionDialogsOpen("successDialog", { type });
          } else if (type === "save") {
            handleActionDialogsOpen("successDialog", { type });
          } else if (type === "download") {
            downloadFile(res);
          }
        })
        .finally(() => {
          setFillFormLoading("");
        });
    },
    [
      business_name,
      dispatch,
      handleActionDialogsClose,
      handleActionDialogsOpen,
      isImmovableProperty,
      merchantNotifications,
      notificationMethod,
      period,
      returnEmail,
    ]
  );

  const step4 = useCallback(() => {
    const commisionsColumn = {
      property: "total_amount_of_commissions",
      header: {
        label: "Commissions",
      },
    };
    const columns = [...tableSchema, commisionsColumn];
    const sortedRows = formatRow(
      Array.isArray(dac7_summary) ? dac7_summary : [],
      columns
    ); // TODO: remove data: {} field from backend response after submit/save, to remove this check Array.isArray()

    const handleSubmitClick = () => {
      handleActionDialogsOpen("confirmSubmit");
    };

    const handleSaveClick = () => {
      fillForm("save");
    };

    const handleDownloadClick = () => {
      fillForm("download");
    };

    return (
      <>
        <Card>
          <DataTable
            columnContentTypes={["text", "text", "text", "text", "numeric"]}
            headings={columns.map(({ header }) => (
              <Text fontWeight='semibold'>{header.label}</Text>
            ))}
            rows={sortedRows}
            hideScrollIndicator
            hoverable
          />
        </Card>
        <SaveBar>
          <ButtonGroup>
            <Button onClick={handlePrev}>{t("createReturns.back")}</Button>
            <Button
              primary
              loading={fillFormLoading === "submit"}
              onClick={handleSubmitClick}
            >
              {t("dac7.submit")}
            </Button>
            <Button
              primary
              loading={fillFormLoading === "save"}
              onClick={handleSaveClick}
            >
              {t("dac7.save")}
            </Button>
            <Button
              primary
              loading={fillFormLoading === "download"}
              onClick={handleDownloadClick}
            >
              {t("dac7.downloadReport")}
            </Button>
          </ButtonGroup>
        </SaveBar>
      </>
    );
  }, [
    tableSchema,
    dac7_summary,
    handlePrev,
    fillFormLoading,
    handleActionDialogsOpen,
    fillForm,
  ]);

  const getStepContent = useCallback(
    (step) => {
      switch (step) {
        case 1:
          return step2();
        case 2:
          return step3();
        case 3:
          return step4();
        default:
          return null;
      }
    },
    [step2, step3, step4]
  );

  const getSchemaByStatus = (status) => {
    return status === "Legal entity" ? legalEntitySchema : naturalPersonSchema;
  };

  const getMerchantEditSchema = (item) => {
    const chunkSize = 2;
    const editSchema = getSchemaByStatus(item.legal_status);

    const groupedTemplateStructure = chunkArray(editSchema, chunkSize);
    const fillGroupInTwoElements = (group) => {
      return group.length < chunkSize
        ? group.concat(Array(chunkSize - group.length).fill(null))
        : group;
    };

    const onChange = (value, id) => {
      const newData = { ...item, [id]: value };
      editActionDialogCellData("merchantSettings", newData);
    };

    return (
      <FormLayout>
        {groupedTemplateStructure.map((group, groupIndex) => (
          <FormLayout.Group key={groupIndex}>
            {fillGroupInTwoElements(group).map((templateElement, index) => (
              <div key={index}>
                {templateElement && (
                  <>
                    {templateElement.type === "input" &&
                      renderInput({
                        ...templateElement.props,
                        value: item[templateElement.props.id],
                        onChange,
                      })}
                    {templateElement.type === "select" &&
                      renderSelect({
                        ...templateElement.props,
                        value: item[templateElement.props.id],
                        options: getSelectOptions(templateElement.props.id),
                        onChange,
                      })}
                    {templateElement.type === "datepicker" &&
                      renderDatepicker({
                        ...templateElement.props,
                        value: item[templateElement.props.id],
                        onChange,
                      })}
                  </>
                )}
              </div>
            ))}
          </FormLayout.Group>
        ))}
      </FormLayout>
    );
  };

  const getRelevantActivityTable = (relevantActivity, setRelevantActivity) => {
    let titles = [
      t("dac7.totalGrossAmountOfTransactions"),
      t("dac7.grossAmountOfTransactionsCoConsumptionAndSales"),
      t("dac7.totalNumberOfTransactions"),
      t("dac7.numberOfTransactionsCoConsumptionAndSales"),
      t("dac7.totalAmountOfCommissionsPaidToMarketplace"),
      t("dac7.taxWithheld"),
    ];

    if (isImmovableProperty) {
      titles = [
        t("dac7.totalGrossAmountOfTransactions"),
        t("dac7.totalNumberOfTransactions"),
        t("dac7.totalAmountOfCommissionsPaidToMarketplace"),
        t("dac7.taxWithheld"),
      ];
    }

    const columns = [
      {
        property: "",
        header: {
          label: "",
        },
        cell: {
          formatters: [
            (value, { rowData, index }) => (
              <Text color='subdued' variant='bodySm'>
                {titles[index]}
              </Text>
            ),
          ],
        },
      },
      {
        property: "",
        header: {
          label: "Q1",
        },
        cell: {
          formatters: [(value, { rowData }) => rowData[0]],
        },
      },
      {
        property: "",
        header: {
          label: "Q2",
        },
        cell: {
          formatters: [(value, { rowData }) => rowData[1]],
        },
      },
      {
        property: "",
        header: {
          label: "Q3",
        },
        cell: {
          formatters: [(value, { rowData }) => rowData[2]],
        },
      },
      {
        property: "",
        header: {
          label: "Q4",
        },
        cell: {
          formatters: [(value, { rowData }) => rowData[3]],
        },
      },
    ];

    const handleChange = (value, id, index) => {
      const updatedArray = [...(relevantActivity?.[id] || [])];
      updatedArray[index] = +value;

      setRelevantActivity({
        ...relevantActivity,
        [id]: updatedArray,
      });
    };

    const schemaItems = isImmovableProperty
      ? relevantActivityImmovableSchema
      : relevantActivitySchema;

    const data = schemaItems.map((schema) =>
      Array.from({ length: 4 }).map((_, index) =>
        renderInput({
          ...schema.props,
          onChange: (value) => handleChange(value, schema.props.id, index),
          value: (relevantActivity?.[schema.props.id]?.[index] || 0).toString(),
        })
      )
    );

    const rows = formatRow(data, columns);

    return (
      <Card>
        <DataTable
          headings={columns.map(({ header }) => (
            <Text color='subdued' variant='bodySm'>
              {header.label}
            </Text>
          ))}
          rows={rows}
          columnContentTypes={["text", "text", "text", "text"]}
          increasedTableDensity
        />
      </Card>
    );
  };

  const getNewType = (baseAmount, relevantActivities) => {
    if (baseAmount[0]) {
      const foundType = relevantActivities.find(
        ({ value }) => value !== baseAmount[0]?.type
      );
      return foundType?.value || baseAmount[0].type;
    } else {
      return relevantActivities[0]?.value || "";
    }
  };

  const renderActivityItem = (
    relevantActivityItem,
    onChange,
    activityItemData
  ) => {
    const { amount = [] } = activityItemData;

    const handleChange = (itemId, key, value) => {
      onChange({
        ...activityItemData,
        amount: amount.map((item) => {
          if (item.id === itemId) {
            return {
              ...item,
              [key]: value,
            };
          }
          return item;
        }),
      });
    };

    const handleRemove = (id) => {
      onChange({
        ...activityItemData,
        amount: amount.filter((item) => item.id !== id),
      });
    };

    const {
      property_type,
      type,
      data,
      id: relevantActivityItemId,
    } = relevantActivityItem;

    const currentRelevantActivityItem =
      relevant_activities.find(({ value }) => value === type) ?? {};

    const title = `${t("dac7.relevantActivity")} - ${
      currentRelevantActivityItem.label
    }`;

    const removeButton = amount.length > 1 && (
      <Tooltip content={`Remove ${title}`}>
        <Button plain onClick={() => handleRemove(relevantActivityItemId)}>
          <img src={deleteIMG} alt='remove relevant activity' />
        </Button>
      </Tooltip>
    );

    const activityForm = isImmovableProperty
      ? immovablePropertyAdditionalInfoSchema.map((item) => {
          const { id } = item.props;

          if (id === "number_of_rented_days" && property_type !== "other") {
            return null;
          }

          const itemProps = {
            ...item.props,
            value: relevantActivityItem[id],
            onChange: (value) =>
              handleChange(relevantActivityItemId, id, value),
            noValidate: true,
          };

          return (
            <>
              {item.type === "input" && renderInput(itemProps)}
              {item.type === "select" &&
                renderSelect({
                  ...itemProps,
                  options: getSelectOptions(id),
                })}
            </>
          );
        })
      : [
          <Select
            key='type-select'
            disabled={amount.length === 2}
            options={relevant_activities}
            value={type}
            onChange={(value) =>
              handleChange(relevantActivityItemId, "type", value)
            }
          />,
        ];

    return (
      <FormLayout key={relevantActivityItemId}>
        <Box align="center" gap="2">
          <Text variant='bodyMd' fontWeight='medium'>
            {title}
          </Text>
          {removeButton}
        </Box>
        <FormLayout.Group>{activityForm}</FormLayout.Group>
        {getRelevantActivityTable(data, (newData) =>
          handleChange(relevantActivityItemId, "data", newData)
        )}
      </FormLayout>
    );
  };

  const renderRelevantActivities = ({ data, onChange }) => {
    const { amount = [] } = data;
    const defaultRelevantActivityItem = createFirstRelevantActivity(amount);
    const isAddButtonVisible = isImmovableProperty ? true : amount.length < 2;

    const onAddRelevantActivity = () => {
      onChange({
        ...data,
        amount: [...amount, defaultRelevantActivityItem],
      });
    };

    return (
      <>
        <br />
        <Box spacing='4'>
          {amount.map((item) => renderActivityItem(item, onChange, data))}
          {isAddButtonVisible && (
            <Button
              plain
              icon={<img src={plusIMG} alt='plus' style={{ marginTop: 3 }} />}
              onClick={onAddRelevantActivity}
            >
              {isImmovableProperty
                ? t("dac7.addInfoForAnotherImmovableProperty")
                : t("dac7.addInfoForAnotherRelevantActivity")}
            </Button>
          )}
        </Box>
      </>
    );
  };

  const renderConfirmNotificationsSendModal = useCallback(() => {
    const onClose = () => {
      handleActionDialogsClose("notificationsCheckbox");
    };
    const onSubmit = () => {
      handleNext();
      onClose();
    };
    return (
      <Modal
        title={t("dac7.notificationConsent")}
        iconType={"warning"}
        visible={actionDialogs.notificationsCheckbox.open}
        onClose={onClose}
        content={
          <>
            <br />
            <Box>
              <Checkbox
                label={t("dac7.consentToSendMerchantNotifications")}
                checked={notificationSendCheckbox}
                onChange={() =>
                  setNotificationSendCheckbox((prevState) => !prevState)
                }
              />
            </Box>
          </>
        }
        footer={
          <ButtonGroup fullWidth>
            <Button onClick={onClose}>{t("dac7.cancel")}</Button>
            <Button
              primary
              disabled={!notificationSendCheckbox}
              onClick={onSubmit}
            >
              {t("dac7.submit")}
            </Button>
          </ButtonGroup>
        }
      />
    );
  }, [actionDialogs, handleActionDialogsClose, notificationSendCheckbox]);

  const renderMerchantSettingsDialog = () => {
    const editSchema = getSchemaByStatus(
      actionDialogs.merchantSettings.cellData.legal_status
    );
    const isEditButtonDisabled = editSchema.some(({ props }) =>
      props.validate(actionDialogs.merchantSettings.cellData[props.id])
    );

    const onEdit = () => {
      dispatch(editMerchantInfo(actionDialogs.merchantSettings.cellData)).then(
        () => {
          handleActionDialogsClose("merchantSettings");
        }
      );
    };

    const onClose = () => {
      handleActionDialogsClose("merchantSettings");
    };

    return (
      <Modal
        title={t("dac7.merchantEdit")}
        visible={actionDialogs.merchantSettings.open}
        infoIconVisible={false}
        onClose={onClose}
        content={
          <>
            <br />
            {getMerchantEditSchema(actionDialogs.merchantSettings.cellData)}
            {renderRelevantActivities({
              data: actionDialogs.merchantSettings.cellData,
              onChange: (newData) =>
                editActionDialogCellData("merchantSettings", newData),
            })}
          </>
        }
        footer={
          <>
            {editingMerchantInfoError && (
              <>
                <Banner status='critical'>{editingMerchantInfoError}</Banner>
                <br />
              </>
            )}
            <ButtonGroup fullWidth>
              <Button onClick={onClose} size='large'>
                {t("dac7.cancel")}
              </Button>
              <Button
                primary
                disabled={isEditButtonDisabled}
                onClick={onEdit}
                loading={editingMerchantInfo}
                size='large'
              >
                {t("dac7.edit")}
              </Button>
            </ButtonGroup>
          </>
        }
      />
    );
  };

  const renderConfirmSubmitDialog = () => {
    const onClose = () => handleActionDialogsClose("confirmSubmit");
    const onSubmit = () => {
      fillForm("submit");
    };
    return (
      <Modal
        title={t("dac7.warning")}
        iconType={"warning"}
        description={""}
        visible={actionDialogs.confirmSubmit.open}
        onClose={onClose}
        content={
          <div style={{ maxWidth: "450px" }}>
            <br />
            <Checkbox
              label={
                <Text variant='bodyMd' color='subdued'>
                  {t("dac7.clickObSubmit")}
                </Text>
              }
              checked={isConfirmSubmitDialog}
              onChange={() => {
                setConfirmSubmitDialog((prev) => !prev);
              }}
            />
          </div>
        }
        footer={
          <ButtonGroup fullWidth>
            <Button onClick={onClose}>{t("dac7.cancel")}</Button>
            <Button
              primary
              onClick={onSubmit}
              disabled={!isConfirmSubmitDialog}
              loading={dac7Loading}
            >
              {t("dac7.submit")}
            </Button>
          </ButtonGroup>
        }
      />
    );
  };

  const renderSuccessDialog = () => {
    const { type } = actionDialogs?.cellData || {};
    const onClose = () => {
      handleActionDialogsClose("successDialog");
      navigate("/reports");
    };
    const getDescription = (type) => {
      if (type === "save") {
        return t("dac7.dac7ReportSuccessfullySaved");
      } else {
        return t("dac7.dac7ReportSuccessfullySubmitted");
      }
    };
    const description = getDescription(type);

    return (
      <Modal
        title={t("eprReporting.success")}
        iconType={"success"}
        visible={actionDialogs.successDialog.open}
        onClose={onClose}
        description={description}
        footer={
          <ButtonGroup>
            <Button primary onClick={onClose} size='large'>
              Ok
            </Button>
          </ButtonGroup>
        }
      />
    );
  };

  const renderUploadMerchantsFileDialog = () => {
    const onClose = () => {
      handleActionDialogsClose("uploadMerchants");
      setShowErrorMessage(null)
    };
    
    const onSubmit = async () => {
      try {
        const data = new FormData();
        data.append("file", merchantsFile, merchantsFile.name);
        data.append("business_name", dataForDac7ReportInfo?.business_name);
        data.append("period", dataForDac7ReportInfo?.period);
        data.append("country", dataForDac7ReportInfo?.country);
        data.append("is_immovable", dataForDac7ReportInfo?.is_immovable);
        
         const res = await dispatch(uploadMerchantsFile(data));
        if (res.errors) {
          setShowErrorMessage(res.errors)
        }
        
        await dispatch(fetchDataForStep2());
        onRemoveFile();
        if (!res.errors)onClose()
      } catch (error) {
        console.error("Error during file upload:", error);
        setShowErrorMessage(error?.error)
      }
    };


    const onDrop = (files) => {
      setMerchantsFile(files[0]);
    };

    const fileUpload = _.isEmpty(merchantsFile) && (
      <DropZone.FileUpload actionTitle={t("dac7.uploadFile")} />
    );

    const onRemoveFile = () => {
      setMerchantsFile({});
    };

    return (
      <Modal
        title={t("dac7.manualUploadMerchantData")}
        infoIconVisible={false}
        visible={actionDialogs.uploadMerchants.open}
        onClose={onClose}
        content={
          <>
            <br />
            {merchantsFile.name ? (
              <Tag onRemove={onRemoveFile}>{merchantsFile.name}</Tag>
            ) : (
              <DropZone allowMultiple={false} onDrop={onDrop}>
                {fileUpload}
              </DropZone>
            )}
          </>
        }
        footer={
          <ButtonGroup fullWidth>
            <div  style={{display: 'flex', flexDirection: 'column'}}>
              {/*{console.log('showErrorMessage', showErrorMessage)}*/}
              <p style={{color: 'red', marginBottom: 8}}>
                {Array.isArray(showErrorMessage) && showErrorMessage.length > 0
                  ? showErrorMessage[0]?.error
                  : typeof showErrorMessage === "string" && showErrorMessage
                }
              </p>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                <div style={{marginRight: '10px'}}>
                  <Button onClick={onClose} size='large'>
                  {t("dac7.cancel")}
                </Button>
                
                </div>
                <div>
                  <Button
                    primary
                    onClick={onSubmit}
                    size='large'
                    loading={uploadingMerchants || dac7Loading}
                  >
                    {t("dac7.upload")}
                  </Button>
                </div>
              </div>
            </div>
          </ButtonGroup>
        }
      />
    );
  };

  const getSelectOptions = (id) => {
    switch (id) {
      case "user_status":
        return ["", ...user_status];
      case "legal_status":
        return [MerchantTypes.legal, MerchantTypes.natural];
      case "property_type":
        return ["", ...property_types];
      case "country":
      case "property_country":
      case "tin_issued_by":
        return [
          "",
          ...countriesDac7.map((country) => ({
            label: country.name,
            value: country.code,
          })),
        ];
      default:
        return [];
    }
  };

  const checkIfAmountRequiredFieldsAreFilled = (data) => {
    const { amount = [] } = data;
    const requiredFields = [
      "land_registration_number",
      "property_address",
      "property_type",
    ];

    if (isImmovableProperty) {
      return amount.some((item) => {
        return requiredFields.some((field) => !item[field]);
      });
    }

    return false;
  };

  const renderAddNewMerchant = () => {
    const onClose = () => {
      handleActionDialogsClose("addMerchant");
    };

    const onSubmit = async () => {
      dispatch(
        addMerchantDac7({
          ...actionDialogs.addMerchant.cellData,
          ...{
            report_country: dataForDac7ReportInfo?.country,
            period: dataForDac7ReportInfo?.period,
            is_immovable: dataForDac7ReportInfo?.is_immovable
          }
        })
      ).then(() => {
        onClose();
      });
    };

    const merchantSchema = [
      {
        type: "select",
        props: {
          id: "legal_status",
          placeholder: t("dac7.legalStatus"),
          validate: () => {},
        },
      },
      merchantIdInput,
      ...getSchemaByStatus(actionDialogs.addMerchant.cellData?.legal_status),
    ];

    const chunkSize = 2;

    const groupedTemplateStructure = chunkArray(merchantSchema, chunkSize);
    const fillGroupInTwoElements = (group) => {
      return group.length < chunkSize
        ? group.concat(Array(chunkSize - group.length).fill(null))
        : group;
    };

    const onChange = (value, id) => {
      const newData = { ...actionDialogs.addMerchant.cellData, [id]: value };
      editActionDialogCellData("addMerchant", newData);
    };

    const isAddButtonDisabled = checkIfAmountRequiredFieldsAreFilled(
      actionDialogs.addMerchant.cellData
    );

    return (
      actionDialogs.addMerchant.cellData && (
        <Modal
          title={t("dac7.addNewMerchant")}
          infoIconVisible={false}
          visible={actionDialogs.addMerchant.open}
          onClose={onClose}
          content={
            <>
              <FormLayout>
                {groupedTemplateStructure.map((group, groupIndex) => (
                  <FormLayout.Group key={groupIndex}>
                    {fillGroupInTwoElements(group).map(
                      (templateElement, index) => (
                        <div key={index}>
                          {templateElement && (
                            <>
                              {templateElement.type === "input" &&
                                renderInput({
                                  ...templateElement.props,
                                  value:
                                    actionDialogs.addMerchant.cellData[
                                      templateElement.props.id
                                    ],
                                  onChange,
                                  noValidate: true,
                                })}
                              {templateElement.type === "select" &&
                                renderSelect({
                                  ...templateElement.props,
                                  value:
                                    actionDialogs.addMerchant.cellData[
                                      templateElement.props.id
                                    ],
                                  options: getSelectOptions(
                                    templateElement.props.id
                                  ),
                                  onChange,
                                  noValidate: true,
                                })}
                              {templateElement.type === "datepicker" &&
                                renderDatepicker({
                                  ...templateElement.props,
                                  value:
                                    actionDialogs.addMerchant.cellData[
                                      templateElement.props.id
                                    ],
                                  onChange,
                                })}
                            </>
                          )}
                        </div>
                      )
                    )}
                  </FormLayout.Group>
                ))}
                {renderRelevantActivities({
                  data: actionDialogs.addMerchant.cellData,
                  onChange: (newData) =>
                    editActionDialogCellData("addMerchant", newData),
                })}
              </FormLayout>
            </>
          }
          footer={
            <ButtonGroup fullWidth>
              <Button onClick={onClose} size='large'>
                {t("dac7.cancel")}
              </Button>
              <Button
                primary
                onClick={onSubmit}
                disabled={isAddButtonDisabled}
                size='large'
                loading={addingMerchant}
              >
                {t("dac7.add")}
              </Button>
            </ButtonGroup>
          }
        />
      )
    );
  };

  return (
    <Page
      fullWidth
      title={
        <Text variant='heading3xl' as='span'>
          {isImmovableProperty
            ? "DAC7 immovable property reporting"
            : t("dac7.dac7Reporting")}
        </Text>
      }
    >
      <PageHelmet title={"Fill Dac7 Report"} />

      <Layout>
        <Layout.Section secondary>
          <Stepper
            steps={steps}
            activeStep={stepIndex}
            titleFontSize={14}
            activeColor='#E4F3FE'
            completeColor='#216DC5'
            circleFontColor='#212B36'
            defaultTitleColor='#919EAB'
            completeTitleColor='#919EAB'
            activeTitleColor='#212B36'
            completeBorderColor={"#367C41"}
            defaultBorderWidth={4}
            defaultBarColor='#ADADAD'
            size={24}
            circleFontSize={14}
          />
        </Layout.Section>
        <Layout.Section>
          <div style={{ marginBottom: 102 }}>{getStepContent(stepIndex)}</div>
          {renderMerchantSettingsDialog()}
          {renderConfirmNotificationsSendModal()}
          {renderConfirmSubmitDialog()}
          {renderSuccessDialog()}
          {renderUploadMerchantsFileDialog()}
          {renderAddNewMerchant()}
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default Dac7Report;
