import { Card, FormLayout, TextField, Text } from '@shopify/polaris'
import * as PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import useAppDispatch from 'hooks/useAppDispatch'
import { createDeclaration } from '../../../../redux/features/declarations/declarationsSlice'
import CustomButton from './CustomButton'
import ModalWindowForTransactionsBuilder from './ModalWindowForTransactionsBuilder'

const initialValues = {title: '', count: null}

function Typography(props) {
	return null
}

Typography.propTypes = {
	variant: PropTypes.string,
	fontWeight: PropTypes.string,
	children: PropTypes.node
}
const Step2NewFunctional = ({
	t,
	returnData,
	data,
	numberInput,
	updateInput,
}) => {
	const [showModalWindowTransactions, setShowModalWindowTransactions] = useState(false)
	const {dataVatReturn, creatingVatReturn} = useSelector((state) => state.declarations)
	const [changeVatStr, setChangeVatStr] = useState(initialValues)
	const [valueCreateVatReturn, setValueCreateVatReturn] = useState(creatingVatReturn)
	const [toggleRequestForCreateDeclaration, setToggleRequestForCreateDeclaration] = useState(false);
	const dispatch = useAppDispatch()
	
	const setDataHandlerForOpenModalTransactions = (cell) => {
		setChangeVatStr(cell)
	}
	useEffect(() => {
		(changeVatStr.title !== '' && changeVatStr.title !== undefined) && openModalWindowTransactions()
	}, [changeVatStr.title]);

	const openModalWindowTransactions = () => setShowModalWindowTransactions(true)
	
	const closeModalWindowTransactions = async () => {
		try {
			setShowModalWindowTransactions(false)
			setChangeVatStr(initialValues)
			const res = dataVatReturn && await dispatch(createDeclaration(dataVatReturn))
			setValueCreateVatReturn({...res})
			updateInput(res)
			res && setToggleRequestForCreateDeclaration(true)
			res && setTimeout(() => setToggleRequestForCreateDeclaration(false), 1000)
		} catch (e) {
			console.log(e)
		}
	}
	
	useEffect(() => {
		if (creatingVatReturn !== valueCreateVatReturn) {
			setValueCreateVatReturn(creatingVatReturn);
		}
	}, [creatingVatReturn, toggleRequestForCreateDeclaration]);

	return (
		<div className='vatReturns-step2NewFunctional'>
			<ModalWindowForTransactionsBuilder
				active={showModalWindowTransactions}
				handleChange={closeModalWindowTransactions}
				data={dataVatReturn}
				changeVatStr={changeVatStr}
			/>

			<Card sectioned>
	      <FormLayout >
	        <FormLayout.Group condensed>
	          <br />
	          <Text as="h6" variant="headingMd"  fontWeight="bold">
	            {t("createReturns.revExVat")}
	          </Text>
	          <Text as="h6" variant="headingMd"  fontWeight="bold">
	            {t("createReturns.vatSum")}
	          </Text>
	        </FormLayout.Group >

	        <FormLayout.Group condensed>
	          <span >
	            {t("createReturns.rates.standard")}{" "}
		          {valueCreateVatReturn.vat_percent ? valueCreateVatReturn.vat_percent + "%" : ""}
	          </span >
		        <CustomButton
			        handler={setDataHandlerForOpenModalTransactions}
			        outputNumber={`${valueCreateVatReturn?.total_sum}`}
			        currency={` ${valueCreateVatReturn?.currency}`}
			        title={'a2'}
			        curent={valueCreateVatReturn?.vat_percent}
		        />

		        {numberInput(
			        "output_vat",
			        "standard_rate",
			        0,
			        "total_vat",
			        valueCreateVatReturn?.total_vat,
			        valueCreateVatReturn?.currency,
			        valueCreateVatReturn?.vat_percent,
			        // updateInput
		        )}
	        </FormLayout.Group >

		      {valueCreateVatReturn?.reduced_rate?.map((item, index) => {
			      return (
				      <FormLayout.Group
					      key={"reduced_rate_row_" + index}
					      condensed
				      >
	              <span >
	                {t("createReturns.rates.reduced")} {item.vat_percent + "%"}
	              </span >

					      <CustomButton
						      handler={setDataHandlerForOpenModalTransactions}
						      outputNumber={`${item.total_sum}`}
						      currency={` ${valueCreateVatReturn.currency}`}
						      title={'a4'}
						      curent={item.vat_percent}
					      />

					      {numberInput(
						      "output_vat",
						      "reduced_rate",
						      index,
						      "total_vat",
						      item.total_vat,
						      valueCreateVatReturn.currency,
						      item.vat_percent,
						      // updateInput
					      )}
	            </FormLayout.Group >
			      );
		      })}

		      {valueCreateVatReturn?.not_standard_rate &&
			      (valueCreateVatReturn.not_standard_rate || []).map((item, index) => {
				      return (
					      <FormLayout.Group
						      key={"not_standard_rate_" + index}
						      condensed
					      >
	                <span >
	                  {t("createReturns.rates.nonstandard")}{" "}
		                {item.vat_percent + "%"}
	                </span >

						      <CustomButton
							      handler={setDataHandlerForOpenModalTransactions}
							      outputNumber={`${item.total_sum}`}
							      currency={` ${valueCreateVatReturn.currency}`}
							      title={'a4_n'}
							      curent={item.vat_percent}
						      />

						      {numberInput(
							      "output_vat",
							      "not_standard_rate",
							      index,
							      "total_vat",
							      item.total_vat,
							      valueCreateVatReturn.currency,
							      item.vat_percent,
							      // updateInput
						      )}
	              </FormLayout.Group >
				      );
			      })}
		      {valueCreateVatReturn.zero_export && (
			      <FormLayout.Group condensed>
	            <span >Export 0%</span >

				      <CustomButton
					      handler={setDataHandlerForOpenModalTransactions}
					      outputNumber={`${valueCreateVatReturn.zero_export.total_sum}`}
					      currency={`${valueCreateVatReturn.currency}`}
					      title={'a32'}
					      curent={0}
				      />

		          <TextField
			          id={"ex-0"}
			          name={"ex-0"}
			          type='text'
			          step={0.01}
			          value={"-"}
			          disabled
		          />
	          </FormLayout.Group >
		      )}
	      </FormLayout >
	    </Card >
		</div>
	)
}

export default Step2NewFunctional